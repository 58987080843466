<template>
  <div class="app-container table-box">
    <el-row class="box search mt20" :gutter="10">
      <el-form
        :inline="true"
        :model="listQuery"
        class="w"
        status-icon
        style="text-align: left"
      >
        <el-row class="box search" :gutter="10">
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                clearable
                v-model="provinceTemp"
                placeholder="请选择省级"
                @change="handleChange($event, 2)"
                value-key="region_name"
                :disabled="provinceDis"
              >
                <el-option
                  v-for="item in provinceOptions"
                  :key="item.region_code"
                  :label="item.region_name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                clearable
                v-model="cityTemp"
                placeholder="请选择市级"
                @change="handleChange($event, 3)"
                value-key="region_name"
                :disabled="cityDis"
              >
                <el-option
                  v-for="item in cityOptions"
                  :key="item.region_code"
                  :label="item.region_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                clearable
                v-model="areaTemp"
                placeholder="请选择区/县"
                @change="handleChange($event, 4)"
                value-key="region_name"
                :disabled="areaDis"
              >
                <el-option
                  v-for="item in areaOptions"
                  :key="item.region_code"
                  :label="item.region_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                clearable
                v-model="townTemp"
                placeholder="请选择街道/镇"
                @change="handleChange($event, 5)"
                value-key="region_name"
              >
                <el-option
                  v-for="item in townOptions"
                  :key="item.region_code"
                  :label="item.region_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                clearable
                v-model="villTemp"
                placeholder="请选择居委会/村"
                value-key="region_name"
                @change="handleChange($event, 6)"
              >
                <el-option
                  v-for="item in villOptions"
                  :key="item.region_code"
                  :label="item.region_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item size="small">
          <el-input
            placeholder="请输入产品名称"
            v-model="listQuery.name"
            clearable
            class="input-with-select"
            @clear="onSearch"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input
            placeholder="请输入区域名称"
            v-model="listQuery.base_name"
            clearable
            class="input-with-select"
            @clear="onSearch"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input
            placeholder="请输入所属企业名称"
            v-model="listQuery.company_name"
            clearable
            class="input-with-select"
            @clear="onSearch"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="small">
        <el-select v-model="listQuery.farmType" placeholder="请选择">
          <el-option
                  v-for="item in options"
                  :key="item.value"
                  placeholder="追溯统计维度"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item size="small">
          <el-col class="line" :span="11">
            <el-date-picker type="date" placeholder="开始日期" v-model="listQuery.add_time"
                            :picker-options="pickerOptionsStart" style="width: 100%;" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" @change="change(1,listQuery.add_time)"></el-date-picker>

          </el-col>
          <el-col class="line" :span="2">至</el-col>
          <el-col class="line" :span="11">
            <el-date-picker type="date" placeholder="结束日期" v-model="listQuery.end_time"
                            :picker-options="pickerOptionsEnd" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            @change="change(2,listQuery.end_time)"></el-date-picker>

          </el-col>
        </el-form-item>
        <el-form-item size="small">
          <el-button
            type="primary"
            @click="onSearch"
            class="general_bgc general_border cfff"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item size="small">
          <el-button
            type="primary"
            @click="exportInfo"
            class="general_bgc general_border cfff"
            >导出报表</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      :data="list"
      v-loading="listLoading"
      border
      fit
      style="width: 100%"
    >
      <el-table-column label="产品名称" prop="name" align="center">
      </el-table-column>
      <el-table-column label="所属区域" prop="base_name" align="center">
      </el-table-column>
      <el-table-column label="所属企业" prop="company_name" align="center">
      </el-table-column>
      <el-table-column label="产量" prop="yield" width="100" align="center">
      </el-table-column>
      <el-table-column
        label="销售总量"
        prop="total_sales"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="打印总数"
        prop="print_count"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
              label="销售记录次数"
              prop="goods_stock_record_count"
              min-width="60"
              align="center"
      >
      </el-table-column>
      <el-table-column label="种养数量" width="100" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.planting_count + "(" + scope.row.unit + ")"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="种养状态" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.state | tagFilter">{{
            scope.row.state | statusFilter
          }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="创建者" align="center" prop="user_name">
      </el-table-column> -->
      <el-table-column label="创建时间" align="center" prop="created_time">
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="180">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            @click="handleGoods(scope.row.id)"
            width="100"
            >绑码详情</el-button
          >
          <el-button
            style="margin-left: 5px"
            size="small"
            type="success"
            width="100"
            @click="handleFarmRecord(scope.row.id)"
            >农事农活记录</el-button
          >
          <el-button
                  style="margin-left: 5px"
                  size="small"
                  type="success"
                  width="100"
                  @click="handleGoodsStockRecord(scope.row.id)"
          >追溯记录次数统计</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="Number(listQuery.pager_openset)"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      ></el-pagination>
    </div>

    <el-dialog title="绑码详情" :visible.sync="goodsDialogVisible" width="55%">
      <!-- <el-form inline :model="filterForm">
        <el-form-item size="small">
          <el-input
            size="small"
            v-model="filterForm.goodsName"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input
            size="small"
            v-model="filterForm.gtin"
            placeholder="二维码值/条形码"
          ></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input
            size="small"
            v-model="filterForm.batchNumber"
            placeholder="批号"
          ></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-checkbox
            v-model="filterForm.flag"
            :true-label="1"
            :false-label="0"
            @change="handleStorage"
            >以品类统计</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleStorage"
            >筛选</el-button
          >
        </el-form-item>
      </el-form> -->
      <el-table
        border
        fit
        :data="plantingGoodsList"
        empty-text="暂无数据"
        height="450"
      >
        <el-table-column
          align="center"
          property="goods_name"
          label="产品名称"
        ></el-table-column>
        <el-table-column align="center" label="二维码值/条形码">
          <template slot-scope="scope">
            <span>{{ scope.row.gtin }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batch_number"
          label="批号"
        ></el-table-column>
        <el-table-column
          align="center"
          property="goods_weight"
          label="重量(kg)"
        ></el-table-column>
        <el-table-column label="绑码实景" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              :disabled="scope.row.imgs && scope.row.imgs.length == 0"
              @click="handleViewPic(scope.row.imgs)"
              >查看图片{{
                scope.row.imgs && scope.row.imgs.length
                  ? "(" + scope.row.imgs.length + "张)"
                  : ""
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="print_count"
          label="打印次数"
        ></el-table-column>
        <el-table-column
          align="center"
          property="screenshot_count"
          label="截图次数"
        ></el-table-column>
        <el-table-column
          align="center"
          property="sales"
          label="销售量"
        ></el-table-column>
        <el-table-column
          align="center"
          property="create_name"
          label="绑码者"
        ></el-table-column>
        <el-table-column
          align="center"
          property="created_time"
          label="绑码时间"
        ></el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background

          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="currentPage1"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="Number(listQuery1.pager_openset)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="plantingGoodsListTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      title="农事农活记录"
      :visible.sync="farmDialogVisible"
      width="55%"
    >
      <el-table
        border
        fit
        :data="farmRecordList"
        empty-text="暂无数据"
        height="450"
      >
        <el-table-column
          align="center"
          property="name"
          label="名称"
        ></el-table-column>
        <el-table-column align="center" label="人工时费用">
          <template slot-scope="scope">
            <span>{{ scope.row.cost }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="remark"
          label="备注"
        ></el-table-column>
        <el-table-column label="农事实景" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              :disabled="scope.row.imgs && scope.row.imgs.length == 0"
              @click="handleViewPic(scope.row.imgs)"
              >查看图片{{
                scope.row.imgs && scope.row.imgs.length
                  ? "(" + scope.row.imgs.length + "张)"
                  : ""
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="created_time"
          label="农事时间"
        ></el-table-column>
        <el-table-column
                align="center"
                property="created_time"
                label="农资使用"
        >
          <template slot-scope="scope">
            <el-button
                    size="small"
                    type="primary"
                    :disabled="scope.row.farmResourceDosages && scope.row.farmResourceDosages.length == 0"
                    @click="handleFarmResourceDosages(scope.row.farmResourceDosages)"
            >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="Number(listQuery2.pager_openset)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="farmRecordListTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
            title="农资使用情况"
            :visible.sync="farmResourceDosagesDialogVisible"
            width="55%"
    >
      <el-table
              border
              fit
              :data="farmResourceDosages"
              empty-text="暂无数据"
              height="450"
      >
        <el-table-column
                align="center"
                property="gtin"
                label="编号"
        >
          <template slot-scope="scope">
            {{scope.row.gtin?scope.row.gtin:'无'}}
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="batch_number"
                label="追溯批号"
        >  <template slot-scope="scope">
          {{scope.row.batch_number?scope.row.batch_number:'无'}}
        </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="goods_name"
                label="农资名称"
        ><template slot-scope="scope">
          {{scope.row.goods_name?scope.row.goods_name:scope.row.farm_resource_name}}
        </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="img_list"
                label="农资图像">
          <template slot-scope="scope">
            <el-button
                    size="small"
                    type="primary"
                    :disabled="scope.row.img_list && scope.row.img_list.length == 0"
                    @click="handleViewPic(scope.row.img_list)"
            >查看图片{{
              scope.row.img_list && scope.row.img_list.length
              ? "(" + scope.row.img_list.length + "张)"
              : ""
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="consumption"
                label="用量">
          <template slot-scope="scope">
            {{scope.row.consumption}}{{scope.row.unit}}
          </template>
        </el-table-column>
      </el-table>
<!--      <div class="pagination-container">-->
<!--        <el-pagination-->
<!--                background-->
<!--                hide-on-single-page-->
<!--                @size-change="handleSizeChange2"-->
<!--                @current-change="handleCurrentChange2"-->
<!--                :current-page="currentPage2"-->
<!--                :page-sizes="[10, 20, 30, 50]"-->
<!--                :page-size="Number(listQuery2.count)"-->
<!--                layout="total, sizes, prev, pager, next, jumper"-->
<!--                :total="farmRecordListTotal"-->
<!--        ></el-pagination>-->
<!--      </div>-->
    </el-dialog>

    <el-dialog
            title="追溯记录次数统计"
            :visible.sync="goodsStockRecordDialogVisible"
            width="55%"
    >
      <el-table
              border
              fit
              :data="goodsStockRecord"
              empty-text="暂无数据"
              height="450"
      >
        <el-table-column
                align="center"
                property="gtin"
                label="商品编号"
        >
          <template slot-scope="scope">
            {{scope.row.gtin?scope.row.gtin:'无'}}
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="goods_name"
                label="商品名称"
        >
        </el-table-column>
        <el-table-column
                align="center"
                property="batch_number"
                label="批号"
        >  <template slot-scope="scope">
          {{scope.row.batch_number?scope.row.batch_number:'无'}}
        </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="old_stock"
                label="减扣前库存量"
        >
        </el-table-column>
        <el-table-column
                align="center"
                property="stock"
                label="减扣库存量"
        >
        </el-table-column>
        <el-table-column
                align="center"
                property="stock"
                label="收货企业"
                width="200"
        >
          <template slot-scope="scope">
            <span>{{scope.row.company_name?scope.row.company_name:"无"}}</span>
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                property="type"
                width="100"
                label="类型"
        >
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.type===0">其他</el-tag>
            <el-tag type="warning" v-if="scope.row.type===1">发货操作</el-tag>
            <el-tag type="success" v-if="scope.row.type===9">消费者购买</el-tag>
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                width="180"
                property="created_time"
                label="操作时间">
        </el-table-column>
        <el-table-column
                align="center"
                property="created_name"
                width="120"
                label="操作者">
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="url"
    />
  </div>
</template>


<script>
import axios from "@/util/api";
import { local,session } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      listLoading: false,
      list: [],
      listQuery: {
        pager_offset: "0",
        pager_openset: "10",
        company_name: "",
        name: "",
        base_name: "",
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        down_name: "",
        add_time:'',
        end_time:'',
        farmType: '1',
      },
      options: [{
        value: '1',
        label: '全部'
      }, {
        value: '2',
        label: '种养过程有农事记录'
      }, {
        value: '3',
        label: '已收获且有农事记录'
      },],

      listQuery1: {
        opt: "1",
        pager_offset: "0",
        pager_openset: "10",
        planting_id: "",
      },

      listQuery2: {
        opt: "1",
        pager_offset: "0",
        pager_openset: "10",
        planting_id: "",
      },
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.listQuery.end_time) {
            return time.getTime() > new Date(this.listQuery.end_time).getTime()
          } else { return time.getTime() > Date.now() }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
      showViewer: false,
      url: [],
      currentPage: 1,
      currentPage1: 1,
      currentPage2: 1,
      listTotal: 0,
      plantingGoodsList: [],
      plantingGoodsListTotal: 0,
      goodsDialogVisible: false,
      farmRecordList: [],
      farmRecordListTotal: 0,
      farmDialogVisible: false,
      farmResourceDosagesDialogVisible:false,
      farmResourceDosages:[],
      goodsStockRecordDialogVisible:false,
      goodsStockRecord:[],
      goodsStockRecordTotal: 0,
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      province: "",
      city: "",
      area: "",
      town: "",
      vill: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],

      userData: {},
    };
  },
  filters: {
    tagFilter(status) {
      //console.log("status: ", status);
      const tagMap = {
        0: "success",
        1: "warning",
      };
      return tagMap[status];
    },
    statusFilter(status) {
      const statusMap = {
        0: "种养中",
        1: "种养结束",
      };
      return statusMap[status];
    },
  },
  computed: {
    provinceDis() {
      if (this.province) return true;
      else return false;
    },
    cityDis() {
      if (this.city) return true;
      else return false;
    },
    areaDis() {
      if (this.area) return true;
      else return false;
    },
  },
  async mounted() {
    this.userData = local.get("user");
    this.initArea();
  },
  methods: {
    change (index, val) {
      var that = this;
      if (val == null) val = '';
      if (index == 1) { that.add_time = (String)(val) }
      if (index == 2) { that.end_time = (String)(val) }
      this.onSearch()
    },

    initArea() {
      let url = "/pc/department/" + this.userData.user_id;
      //预加载监管部门区域
      axios.get(url).then((res) => {
        res = res.data.department;
        let i = 0;
        if (res.length > 0) {
          let level = res[0].level;
          for (let j = 0; j < res.length; j++) {
            if (level > res[j].level) i = j;
          }
          this.province = res[i].province;
          this.city = res[i].city;
          this.area = res[i].area;
          this.town = res[i].town;
          this.vill = res[i].vill;
          this.provinceTemp = res[i].province;
          this.cityTemp = res[i].city;
          this.areaTemp = res[i].area;
          this.townTemp = res[i].town;
          this.listQuery.province = res[i].province;
          this.listQuery.city = res[i].city;
          this.listQuery.area = res[i].area;
          this.listQuery.town = res[i].town;
          this.userData.province = this.provinceTemp;
          this.userData.city = this.cityTemp;
          this.userData.area = this.areaTemp;
          this.handleUserLevel(this.userData);
          this.initData();
        }
      });
    },
    onSearch() {
      this.initData();
    },
    initData() {
      this.listLoading = true;
      axios
        .get("/pc/company-planting-category/list", this.listQuery)
        .then((response) => {
          let data = response.data.planting_category_list;
          this.list = data;
          this.listTotal = response.data.planting_category_count;
          this.listLoading = false;
        });
    },
    exportInfo() {
      this.loading = true;
      this.timer = setInterval(() => {
        this.check("export-company-planting-list");
      }, 2000);
      let statename = "种养品类信息统计报表";
      this.listQuery.down_name = statename;
      axios
        .fileget(
          "/pc/company-planting-category/export-planting-category-list",
          this.listQuery
        )
        .then((v) => {});
    },
    check(d_name) {
      axios.get("/pc/report/check", { down_name: d_name }).then((v) => {
        if (v.data == true) {
          clearInterval(this.timer);
          this.loading = false;
        }
      });
    },
    handleGoodsStockRecord(id){
      this.goodsStockRecordDialogVisible = true;
      axios.get("/pc/company-planting-category/goodsStockRecordList",{id:id}).then(res=>{
        if (res.code==200){
          if(res.data.company_goods_stock_record_list.length>0) {
            this.goodsStockRecord = res.data.company_goods_stock_record_list.map(m => {
                      this.$set(m, "goods_name", JSON.parse(m.gtin_info).goodsName);
                      return m;
                    }
            );
          }else
            this.goodsStockRecord = res.data.company_goods_stock_record_list;

          this.goodsStockRecordTotal = res.data.company_goods_stock_record_count;
        }
      })
    },
    handleSizeChange(val) {
      this.listQuery.pager_openset = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.listQuery.pager_offset = String((val - 1) * 10);
      this.initData();
    },
    handleSizeChange1(val) {
      this.listQuery1.pager_openset = val;
      this.handleGoods();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.listQuery1.pager_offset = String((val - 1) * 10);
      this.handleGoods();
    },
    handleSizeChange2(val) {
      this.listQuery2.pager_openset = val;
      this.handleFarmRecord();
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.listQuery2.pager_offset = String((val - 1) * 10);
      this.handleFarmRecord();
    },
    handleViewPic(imgs) {
      let url = imgs.map((item) => item.url);
      this.url = url;
      this.showViewer = true;
    },
    handleFarmResourceDosages(item){
      this.farmResourceDosagesDialogVisible = true;
      this.farmResourceDosages = item;
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleGoods(id) {
      if (id) {
        this.listQuery1.planting_id = id;
      }
      this.plantingGoodsList = [];
      axios
        .get("/pc/company-planting-category/goodsList", this.listQuery1)
        .then((response) => {
          let data = response.data.planting_goods_list;
          data.forEach((item) => {
            item.imgs = [];
            if (item.img_list) {
              item.imgs = JSON.parse(item.img_list);
            }
          });
          this.plantingGoodsList = data;
          this.plantingGoodsListTotal = response.data.planting_goods_count;
        });
      this.goodsDialogVisible = true;
    },
    handleFarmRecord(id) {
      if (id) {
        this.listQuery2.planting_id = id;
      }
      this.farmRecordList = [];
      axios
        .get("/pc/company-planting-category/farmRecordList", this.listQuery2)
        .then((response) => {
          let data = response.data.planting_farmRecord_list;
          data.forEach((item) => {
            item.imgs = [];
            if (item.img_url) {
              item.imgs = item.img_url;
            }
          });
          this.farmRecordList = data;
          this.farmRecordListTotal = response.data.farmRecord_count;
        });
      this.farmDialogVisible = true;
    },
    async handleUserLevel(userData) {
      if (parseInt(userData.user_type) > 0) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          (item) => item.region_name == userData.province
        );
        this.provinceTemp = province;
        if (
          parseInt(userData.user_type) >= 1 &&
          userData.province != null &&
          userData.province != ""
        ) {
          this.cityOptions = await this.fetchRegionData(
            2,
            province.region_code
          );
          let city = this.cityOptions.find(
            (item) => item.region_name == userData.city
          );
          if (parseInt(userData.user_type) >= 2 && userData.city != null) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              (item) => item.region_name == userData.area
            );
            this.cityTemp = city;
            if (userData.area != null) {
              this.townOptions = await this.fetchRegionData(
                4,
                area.region_code
              );
              let town = this.townOptions.find(
                (item) => item.region_name == userData.town
              );
              this.areaTemp = area;
              if (userData.town != null) {
                this.villOptions = await this.fetchRegionData(
                  5,
                  town.region_code
                );
                let vill = this.villOptions.find(
                  (item) => item.region_name == userData.vill
                );
                this.townTemp = town;
                if (userData.vill != null) {
                  this.villTemp = vill;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData(type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then((response) => {
            resolve(response.data.region_list);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async handleChange(item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = "";
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }

      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.listQuery.province = item.region_name;
          this.listQuery.city = "";
          this.listQuery.area = "";
          this.listQuery.town = "";
          this.listQuery.vill = "";
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.listQuery.city = item.region_name;
          this.listQuery.area = "";
          this.listQuery.town = "";
          this.listQuery.vill = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.listQuery.area = item.region_name;
          this.listQuery.town = "";
          this.listQuery.vill = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.listQuery.town = item.region_name;
          this.townTemp = item.region_name;
          this.listQuery.vill = "";
          this.villTemp = "";
          break;
        case 6:
          this.listQuery.vill = item.region_name;
          this.villTemp = item.region_name;
          break;
      }
      this.currentPage = 1;
      this.listQuery.pager_offset = "0";
      this.initData();
    },
  },
};
</script>

<style scoped>
.table-box {
  padding: 10px 20px;
}
</style>
